import { React, useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import AppHeaderDefault from "./AppHeaderDefault";
import HelpPopup from "./HelpPopup";
import {PopupWrapper, IPopup} from "./PopupWrapper";

//<AppHeaderProduct/>
const AppHeader = () => {

    const location = useLocation();
    const [currentHeader, setCurrentHeader] = useState();
    const [isHelpOpen, setIsHelpOpen] = useState(false);

    useEffect(() => {
        let useHeader = <AppHeaderDefault onHelpClick={() => { setIsHelpOpen(isHelpOpen => !isHelpOpen)} }/>;    //Default header
        let path = location.pathname;
        //console.log(location);
        if(path.includes("/view/")){
            //useHeader = <AppHeaderProduct/>;    //Default Product View header

            if(location.state && location.state.prevPage){
                if(location.state.prevPage.includes("/wishlist")){
                    //useHeader = <AppHeaderWishlist/>
                }
            }   
        }
        else if(path == "/wishlist"){
            //useHeader = <AppHeaderWishlist/>
        }
        setCurrentHeader(useHeader);
    }, [location.pathname]);

    return (
        <>
            <div className="relative">
                {currentHeader}
                <PopupWrapper popup={IPopup.Help} isOpen={isHelpOpen} onClickClose={() => { setIsHelpOpen(false); }} />
            </div>
        </>
    )
};

export default AppHeader;