import { React, useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { RoomContext } from "../context/RoomContext";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({});

const JoinRoom = () => {
  const classes = useStyles();
  const { roomId, actions } = useContext(RoomContext);
  const [fieldRoomId, setFieldRoomId] = useState("");

  useEffect(() => {}, [roomId]);

  return (
    <Container>
      <Typography variant="caption" gutterBottom>
        Current Room: {roomId}
      </Typography>

      <Box m={2}>
        <TextField
          id="roomId"
          label="Room ID"
          placeholder="Room ID"
          value={fieldRoomId}
          onChange={(event) => {
            setFieldRoomId(event.target.value);
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            actions.joinRoom(fieldRoomId);
          }}
        >
          Join Room
        </Button>
      </Box>
    </Container>
  );
};

export default JoinRoom;
