import React, { useContext, useState } from 'react';
import { RoomContext } from '../../context/RoomContext';

const EmailPopup = () => {

    const [emailValue, setEmailValue] = useState("");
    const {roomId, actions } = useContext(RoomContext);
    const [response, setResponse] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        let errorMessage = "Sorry, an error occurred. Please try again.";
        setLoading(true);
        setResponse("");
        e.preventDefault();
        fetch('/api/rooms/SendWishlistEmail', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
                EmailTo: emailValue,
                RoomId: roomId
            })
        })
        .then(res => {
            //console.log(res);
            if(res.ok){
                setResponse("Email successfully sent.");    
            }
            else{
                setResponse(errorMessage);
            }
        })
        .catch(err => {
            console.log(err);
            setResponse(errorMessage);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <div className="px-4 text-center">
                <div className="mb-3 text-2xl">Email your favourites</div>
                <div className="mb-4 px-8">Please enter your email address and we'll send your favourites list to you.</div>
                <form onSubmit={handleSubmit}> 
                    <label className="text-left flex flex-col ">
                        Email address
                        <input className="text-black py-2 border-2 border-gray-200" name="email" value={emailValue} onChange={(e) => { setEmailValue(e.target.value) }} />
                    </label>
                    <button className="bg-black border my-4 p-3 w-full flex justify-center" type="submit"><div className="mr-2">Send</div>{loading && <img className="h-6 animate-spin" src="/images/spinner.png"/>}</button>
                </form>
                <div>{response}</div>
                <div>Your information will be passed on to the store team, so that they can help you.</div>
            </div>
        </>
    )
}

export default EmailPopup;