import React, { useEffect, useRef, useState } from 'react';

const ReadMore = ({maxHeight, content, disabled}) => {

    const container = useRef();
    const [currMaxHeight, setCurrMaxHeight] = useState(disabled ? "unset" : maxHeight);
    const [showToggleButton, setShowToggleButton] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if(container){
            //console.log(container.current.scrollHeight, maxHeight);
            if(disabled){
                
            }
            else{
                if(container.current.scrollHeight < maxHeight){
                    setCurrMaxHeight("unset");
                }
                else{
                    setShowToggleButton(true);
                }
            }
        }      
    }, [content, maxHeight]);

    useEffect(() => {
        if(!disabled){
            if(expanded){
                setCurrMaxHeight(container.current.scrollHeight);
            }
            else{
                setCurrMaxHeight(maxHeight);
            }
        }
    }, [expanded])

    /*
        read more button
        state to show it
        onclick to toggle currMaxHeight state
    */

    return (
        <>
        <div style={{maxHeight: currMaxHeight + "px"}} className="relative overflow-hidden transition-maxHeight duration-500 whitespace-pre-line pb-4" ref={container}>
            {content}               
            {/*<div className={`absolute w-full h-full top-0 transition-background duration-1000 ${expanded ? "bg-img-transparent" : "read-more__gradient"}`}></div>*/}
        </div>
        {
            showToggleButton &&
            <button className="w-full p-3 border border-solid border-black font-bold" onClick={() => { setExpanded(v => !v); }}>{expanded ? "Read Less" : "Read More"}</button>
        }        
        </>
    )
};

export default ReadMore;
