import { React, useContext, useEffect, useState } from 'react';
import useHistoryHelper from '../components/HistoryHelpers';
import { IPopup, PopupWrapper } from '../components/MobileApp/PopupWrapper';
import { RoomContext } from "../context/RoomContext";

const Wishlist = () => {


    const [isEmailOpen, setIsEmailOpen] = useState(false);
    const [image, setImage] = useState();

    const { roomId, products, actions } = useContext(RoomContext);

    const removeFromWishlist = (Id, colourId) => {
        actions.removeProductFromRoom(roomId, Id, colourId);
    }

    useEffect(() => {
        console.log(products);
    }, [products]);

    useEffect(() => {
        fetch('/api/products/GetRandomProductImage')
            .then(res => res.text())
            .then(res => {
                setImage("products/range/" + res);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    return(
        <>
            <div style={{minHeight: "56vh"}} className="p-4 pb-12 shadow-inner flex flex-col">
                <div className="flex justify-between align-center">
                    <div className="text-5xl font-bold cr-product__heading-text uppercase">
                        Favourites
                    </div>
                    
                </div>
                <div className="flex my-4">
                    <div className="w-20">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                    </div>
                    <div className="font-bold pl-2 text-sm">
                        Scan the QR code on the large screen in-store and our experts will talk you through the finer details
                    </div>
                </div>
                {
                        products.length > 0 
                        ?
                            <>
                                <div className="grid  grid-cols-2 gap-x-2 gap-y-6">
                                    {
                                        products.map((product) => {
                                            return <WishlistProductBlock key={`Wishlist_Block_${product.Id}`} product={product} removeFromWishlist={removeFromWishlist}/>
                                        })
                                    }
                                </div>
                                <button onClick={() => { setIsEmailOpen(true); }} className="mt-4 disabled:border-gray-400 disabled:text-gray-400 font-semibold h-14 flex items-center justify-center border-2 border-black" disabled={products.length == 0}>
                                    <div className="w-9 mr-2 flex items-center" onClick={() => { setIsEmailOpen(true); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                                    </div>
                                    <div>
                                        Email me my wishlist
                                    </div>
                                </button>
                            </>
                        :
                            <div className="flex-grow flex items-center flex-col">
                                {image && <img style={{maxHeight: "300px"}} className="w-full object-cover" src={`/api/products/image?image=${image}&size=500x500`}/> }
                                <div className="mt-4">You don't have any products in your wishlist, use your phone to scan QR codes around the shop to create one.</div>
                            </div>
                }   
                
                
            </div>
            <PopupWrapper popup={IPopup.Email} isOpen={isEmailOpen} onClickClose={() => { setIsEmailOpen(false); }} />
        </>
    )
};

const WishlistProductBlock = (props) => {
    const product = props.product;
    const selectedVariant = props.product.Variants[0];
    const history = useHistoryHelper();

    return (
        <div className="flex flex-col relative">
            <div onClick={() => { history.push(`/view/${product.Id}${product.SelectedVariant ? product.SelectedVariant.ColourId : ""}` )}}>
                <div className="relative pt-full border border-gray-500">
                    <img className="absolute object-contain top-0 h-full w-full object-cover" src={`/api/products/image?image=products/range/${product.Images[0]}&size=200x200`} />
                </div>
                <div className="mt-2">
                    {
                        <img className="object-contain pl-1 mb-1" src={`/images/brand/${product.Brand ? product.Brand.toLowerCase() : "core"}.png`}/> 
                    }
                    <div className="font-bold text-2xl">{product.Range}{product.SelectedVariant && " " +product.SelectedVariant.ShortName}</div>
                </div>
                <div className="w-3/5">
                    <div className="grid grid-cols-5 gap-1.5">
                        {
                            product.SelectedVariant 
                            ? 
                            <div className={`pt-full relative`}><img src={`/api/products/image?image=products/swatches/${product.SelectedVariant.Swatch}&size=50x50`}  className="absolute top-0 left-0 h-full" ></img></div>
                            :
                            product.Variants.map((variant, i) => {
                                if(i < 10){
                                    return <div key={`colourBlock_${variant.ColourId}${variant.ShortName}`} className={`pt-full relative`}><img src={`/api/products/image?image=products/swatches/${variant.Swatch}&size=50x50`}  className="absolute top-0 left-0 h-full" ></img></div>
                                }
                                
                            })
                        }
                    </div>
                </div>
                {/*
                <div className="mt-2">
                {
                    selectedVariant.IsOnOffer
                    ?
                    <>
                        <div className="line-through text-sm font-bold">Was £{selectedVariant.RetailPrice} m<sup>2</sup></div>
                        <div className="font-bold text-lg leading-4">£{selectedVariant.OfferPrice} m<sup>2</sup></div>
                    </>
                    :
                        <div className="font-bold text-lg leading-4">£{selectedVariant.RetailPrice} m<sup>2</sup></div>   
                }
                </div>
                */}
            </div>
            <div className="absolute right-2 top-2 w-2/6">
                <div className="relative cursor-pointer wishlist-remove__button" onClick={() => { props.removeFromWishlist(product.Id, product.SelectedVariant ? product.SelectedVariant.ColourId : undefined); }}>
                    <svg className="text-black" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="2 4 20 19" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                    <div className="text-white center-heart-icon font-bold text-3xl">+</div>
                </div>
            </div>
        </div>
    )
}

export default Wishlist;