import React, { useReducer, createContext, useMemo, useEffect, useRef, useState } from "react";
import Axios from "axios";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { SendMessage } from "../helpers/CefSharp";

const testProducts = 
  {
    "Id": "A409",
    "RangeText": "This Birch carpet is part of our Country Living collection which means it is crafted out of the finest wool.  With a lovely soft textured loop pile and a range of ten neutral shades, this naturally durable carpet will be a good fit for most interior styles.",
    "CarpetType": "Carpet",
    "Brand": "Country Living",
    "Range": "Birch",
    "Images": [
      "A409_roomshot_Country-Living-Birch-Linen_181-(2).jpg",
      "A409_roomshot_Country-Living-Birch-Linen_181-(2).jpg",
      "A409_roomshot_Country-Living-Birch-Linen_181-(2).jpg"
    ],
    "Icons": [
      "icon-country-living-wool.png",
      "icon-country-living-durable.png",
      "icon-country-living-moth.png",
      "icon-country-living-width.png"
    ],
    "RoomIcons": [
      "WWB_HSL_SPOT.png",
      "WWB_Lounge_SPOT.png",
      "WWB_Dining_Room_SPOT.png",
      "WWB_Bedroom_SPOT.png"
    ],
    "Variants": [
      {
        "Sku": "A40902560509",
        "RangeId": "A409",
        "ColourId": "0256",
        "ShortName": "Mineral",
        "Width": "4m",
        "Swatch": "A4090256_Birch-75-Mineral-Carpet_swatch.jpg",
        "RetailPrice": 34.99,
        "OfferPrice": 34.99,
        "IsOnOffer": false
      },
      {
        "Sku": "A40903560509",
        "RangeId": "A409",
        "ColourId": "0356",
        "ShortName": "Platinum",
        "Width": "4m",
        "Swatch": "A4090356_Birch-174-Platinum-Carpet_swatch.jpg",
        "RetailPrice": 34.99,
        "OfferPrice": 34.99,
        "IsOnOffer": false
      },
      {
        "Sku": "A40905560509",
        "RangeId": "A409",
        "ColourId": "0556",
        "ShortName": "Granite",
        "Width": "4m",
        "Swatch": "A4090556_Birch-76-Granite-Carpet_swatch.jpg",
        "RetailPrice": 34.99,
        "OfferPrice": 34.99,
        "IsOnOffer": false
      },
      {
        "Sku": "A40911560509",
        "RangeId": "A409",
        "ColourId": "1156",
        "ShortName": "Mink",
        "Width": "4m",
        "Swatch": "A4091156_Birch-91-Mink-Carpet_swatch.jpg",
        "RetailPrice": 34.99,
        "OfferPrice": 34.99,
        "IsOnOffer": false
      },
      {
        "Sku": "A40913560509",
        "RangeId": "A409",
        "ColourId": "1356",
        "ShortName": "Hedgehog",
        "Width": "4m",
        "Swatch": "A4091356_Birch-192-Hedgehog-Carpet_swatch.jpg",
        "RetailPrice": 34.99,
        "OfferPrice": 34.99,
        "IsOnOffer": false
      },
      {
        "Sku": "A40924560509",
        "RangeId": "A409",
        "ColourId": "2456",
        "ShortName": "Biscuit",
        "Width": "4m",
        "Swatch": "A4092456_Birch-94-Biscuit-Carpet_swatch.jpg",
        "RetailPrice": 34.99,
        "OfferPrice": 34.99,
        "IsOnOffer": false
      },
      {
        "Sku": "A40927560509",
        "RangeId": "A409",
        "ColourId": "2756",
        "ShortName": "Fawn",
        "Width": "4m",
        "Swatch": "A4092756_Birch-92-Fawn-Carpet_swatch.jpg",
        "RetailPrice": 34.99,
        "OfferPrice": 34.99,
        "IsOnOffer": false
      },
      {
        "Sku": "A40970560509",
        "RangeId": "A409",
        "ColourId": "7056",
        "ShortName": "Linen",
        "Width": "4m",
        "Swatch": "A4097056_Birch-70-Linen-Carpet_swatch.jpg",
        "RetailPrice": 34.99,
        "OfferPrice": 34.99,
        "IsOnOffer": false
      },
      {
        "Sku": "A40972560509",
        "RangeId": "A409",
        "ColourId": "7256",
        "ShortName": "Parchment",
        "Width": "4m",
        "Swatch": "A4097256_Birch-72-Parchment-Carpet_swatch.jpg",
        "RetailPrice": 34.99,
        "OfferPrice": 34.99,
        "IsOnOffer": false
      }
    ]
  }

const initialState = {
  isPresenting: false,
  connection: null,
  selectedProduct: null,
  roomId: null,
  products: [],
  showWarning: false,
  offline: false,
  options: {
    ShowQrCode: false
  },
  idle: {
    timer: 0,
    warningTime: 3,
    finalTime: 5,
    timerTimeout: null
  }
};

const LOAD_PRESENTATION = "LOAD_PRESENTATION";
const SELECT_PRODUCT = "SELECT_PRODUCT";
const CLEAR_PRODUCT = "CLEAR_PRODUCT";
const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
const SET_CONNECTION = "SET_CONNECTION";
const STOP_CONNECTION = "STOP_CONNECTION";
const SET_WARNING = "SET_WARNING";
const SET_OFFLINE = "SET_OFFLINE";
const SET_TIMER_OBJECT = "SET_TIMER_OBJECT";
const SET_TIMER = "SET_TIMER";
const SET_OPTIONS = "SET_OPTIONS";

const reducer = (state, action) => {
  switch (action.type) {
    case CLEAR_PRODUCT:
      return {...state, selectedProduct: null };
    case SELECT_PRODUCT:
      return {...state, selectedProduct: action.payload };
    case LOAD_PRESENTATION:
      return { ...state, isPresenting: true, products: action.payload.products, roomId: action.payload.roomId };
    case UPDATE_PRODUCTS:
      return { ...state, products: action.payload };
    case SET_CONNECTION:
      return { ...state, connection: action.payload };
    case STOP_CONNECTION:
      return { ...action.payload};
    case SET_WARNING:
      return { ...state, showWarning: action.payload };
    case SET_OFFLINE:
      return { ...state, offline: action.payload };
    case SET_TIMER_OBJECT:
      return { ...state, idle: { ...state.idle, timerTimeout: action.payload }};
    case SET_TIMER: 
      return { ...state, idle: { ...state.idle, timer: action.payload }};
    case SET_OPTIONS:
      return { ...state, options: action.payload};
    default:
      return state;
  }
};

const PresenterContext = createContext();

const PresenterProvider = (props) => {
  const test = false;
  const [presenterState, dispatch] = useReducer(reducer, initialState);
  const presenterRef = useRef(initialState);

  useEffect(() => {
    presenterRef.current = presenterState;
  });

  useEffect(() => {
    resetTimeout();    
  }, [presenterState.isPresenting]);

  useEffect(() => {

    getOptions();

    const eventsToTimeout = [
      'mousedown'
    ];

    const timeout = setInterval(incrementTimer, 60000);
    dispatch({ type: SET_TIMER_OBJECT, payload: timeout});
    
    for(let i = 0; i < eventsToTimeout.length; i++){
      window.addEventListener(eventsToTimeout[i], resetTimeout);
    }

    let pingInterval = setInterval(() => {
      if(presenterRef.current.connection){
        presenterRef.current.connection.invoke("Ping")
        .then((res) => {
          //console.log("Successful ping");
        })
        .catch(err => {
          lostConnection();
          console.log(err);
        })
      }
    }, 1000 * 60 * 0.1);  //6 seconds

    return () => {
      for(let i = 0; i < eventsToTimeout.length; i++){
        window.removeEventListener(eventsToTimeout[i], resetTimeout);
      }
    }
  }, []);

  useEffect(() => {
    var timer = presenterState.idle.timer;
    //console.log("useEffect timer", timer);
    if(timer == presenterRef.current.idle.warningTime){
      showWarning();
    }
    if(timer == presenterRef.current.idle.finalTime){
      stopConnection();
    }
  }, [presenterState.idle.timer]);

  const getOptions = async () => {

    try{
      const response = await Axios.get("/api/rooms/GetOptions");
      dispatch({ type: SET_OPTIONS, payload: response.data });
    }
    catch(err){
      console.error(err);
    }
  }

  const resetTimeout = () => {
    //console.log("resetting timeouts");
    dispatch({ type: SET_TIMER, payload: 0 });
    hideWarning();
  }

  const incrementTimer = () => {
    let increment = 0;
    if(presenterRef.current.isPresenting){    
      increment = presenterRef.current.idle.timer + 1;
      //console.log("Incrementing timer to", increment);
      dispatch({ type: SET_TIMER, payload: increment });
    }   
  }

  const setOffline = (value) => {
    dispatch( {type: SET_OFFLINE, payload: value });
  }
  
  const showWarning = () => {
    dispatch({type: SET_WARNING, payload: true });
  }

  const hideWarning = () => {
    dispatch({type: SET_WARNING, payload: false });
  }

  const clearProduct = () =>{
    if(presenterState.selectedProduct){
      dispatch({ type: CLEAR_PRODUCT });
    }
  }

  const loadPresentation = (roomId) => {
    if(test){
      let products = [];
      for(let i = 0; i < 1; i++){
        products.push(testProducts);
      }
      dispatch({ type: LOAD_PRESENTATION, payload: { products: products, roomId: 123123 } });
    }
    else{
      Axios.get(`/api/Rooms/GetById?roomId=${roomId}`)
        .then((res) => {
          dispatch({ type: LOAD_PRESENTATION, payload: {products: res.data.Products, roomId: roomId } });
          SendMessage([
            {
                command: "showoverlay",
                payload: false
            }
          ]);
        })
        .catch((err) => {
          console.error(
            `Failed to load presentation for room: ${roomId}!`,
            err
          );
        });
    }
  }

  const startConnection = () => {
    const connection = new HubConnectionBuilder()
      .withUrl(`/api/hubs/presentation`)
      .withAutomaticReconnect()
      .build();

    connection.start()
      .then(() => {
        gainedConnection(connection);

        console.log(`Client connected to hub!`, connection.connectionId, connection);
        dispatch({ type: SET_CONNECTION, payload: connection });

        connection.onreconnected((connectionId) => {
          console.log(`Reconnected! ConnectionId: ${connectionId} - Current ConnectionId: ${connection.connectionId}`);
          gainedConnection(connection);
        });
    
        connection.onclose((err) => {
          console.error("Connection closed! error:", err);
          restartConnection(err);
        });
    
        connection.onreconnecting((err) => {
          if(err){
            console.error("Connection reconnected because of an error:", err);
            restartConnection();
          }
          
          //TODO - What if reconnecting fails?          
        });
    
        connection.on("StartPresentation", (roomId) => {
          if(presenterRef.current.isPresenting){
            console.log("Not overwriting presentation");
          }
          else{
            console.log(`Starting presentation for room: ${roomId}.`);
            loadPresentation(roomId);
          }          
        });
      })
      .catch((err) => {
        //startConnection();
        restartConnection(err);
        console.error("Failed to connect to hub!", err);
      });

      
  }

  const restartConnection = async (err) => {
      
    try{
      if(err){
        console.log("Connection error:", err);
      
        //Logging error as a json string and then logging it on the server.
        const response = await Axios.post("/api/rooms/LogConnectionError", `error=${JSON.stringify(err)}`);

        console.log("Logged error to backend", response);
      }
    }
    catch(err){
      console.log("Error logging response to server", err);
    }   
    finally{
      window.location.reload();
    }
  }

  const lostConnection = () =>{
    let attemps = localStorage.getItem("attempts");
    attemps = null ? 1 : attemps;
    let increment = attemps++;
    localStorage.setItem("attempts", increment);
    console.log("Failed to connect, reconnect attemp", increment);
    setOffline(true);
  }

  const gainedConnection = (connection) => {
    localStorage.removeItem("attemps");
    setOffline(false);

    SendMessage([
      {
          command: "updateqrcode",
          payload: window.location.hostname + `/present/${connection.connectionId}`
      },
      {
          command: "showoverlay",
          payload: true
      }
    ]);
  }

  const stopConnection = () => {
    if(presenterState.connection){
      presenterState.connection.stop();          
    }    
    //dispatch({ type: STOP_CONNECTION, payload: { ...initialState } });
    //startConnection();
    //Or just refresh the page?
    restartConnection();
  }

  const contextValue = useMemo(() => {
    return {
      presenterState,
      actions: {
        clearProduct: () => {
          clearProduct();
        },
        selectProduct: (productId, colourId) => {
          //console.log(productId, colourId, presenterState.products);
          let product = presenterState.products.filter((prod) => prod.Id == productId);
          if(product.length > 1){
            product = product.filter(prod => prod.SelectedVariant.ColourId == colourId);
          }
          if(product){
            dispatch({ type: SELECT_PRODUCT, payload: product[0]});
          }          
        },
        removeProduct: (productId, colourId) => {
          //console.log("Removing", productId, "from wishlist", colourId);
          Axios.post('/api/rooms/removeProduct', {
            RangeId: productId,
            RoomId: presenterState.roomId,
            ColourId: colourId
          })
          .then((res) => {
            dispatch({ type: UPDATE_PRODUCTS, payload: res.data.Products });
            clearProduct(); //If on a product, will clear it, if on the slideshow screen will do nothing.
          })
          .catch((res) => {
            console.log(res);
          })          
        },
        loadPresentation: (roomId) => {
          loadPresentation(roomId);          
        },
        setConnection: () => {
          startConnection();
        },
        stopConnection: () => {
          stopConnection();
        },
        showWarning: () => {
          showWarning();
        },
        hideWarning: () => {
          hideWarning();
        },
        setOffline: (value) => {
          setOffline(value);
        }
      },
    };
  }, [presenterState]);

  return (
    <PresenterContext.Provider value={contextValue}>
      {props.children}
    </PresenterContext.Provider>
  );
};

export { PresenterProvider, PresenterContext };
