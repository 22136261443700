import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { RoomProvider } from "./context/RoomContext";
import { PresenterProvider } from "./context/PresenterContext";

// Import Routes
import Product from "./routes/Product";
import JoinRoom from "./routes/JoinRoom";
import Presenter from "./routes/Presenter";
import Present from "./routes/Present";
import PageWrapper from "./components/MobileApp/PageWrapper";
import Wishlist from "./routes/Wishlist";

// Import CSS
import './App.scss';

const Pages = [
  {
    routeProps: {
      path:  "/",
      exact: true,
    },
    componentProps: {
      component: "QRPoc",
      hideHeader: true,
      hideFooter: true
    },    
    redirect: {
      condition: true,
      to: "/wishlist"
    }
  },
  {
    routeProps: {
      path: "/wishlist"
    },
    componentProps: {
      component: <Wishlist/>,
    }
  },
  {
    routeProps: {
      path:  "/view/:id",
    },
    componentProps: {
      component:  <Product />
    }    
  },
  {
    routeProps: {
      path:  "/present/:connectionId",
    },
    componentProps: {
      component: <Present />,
      hideHeader: false,
      hideFooter: false
    }    
  },
  {
    routeProps: {
      path:  "/joinRoom",
    },
    componentProps: {
      component: <JoinRoom />
    }    
  },
  {
    routeProps: {
      path:  "/presenter",
    },
    componentProps: {
      fitToScreen: true,
      component: <PresenterProvider><Presenter/></PresenterProvider>,
      hideHeader: true,
      hideFooter: true
    }    
  },
  {
    routeProps: {
      path:  "*",
    },
    componentProps: {
      component: "404"
    }    
  },
]

const App = () => {
  return (
    <Router>
      <RoomProvider>
        <Switch>
          {
            Pages.map((page, i) => {
              return  <Route {...page.routeProps} key={page.routeProps.path}>
                        {
                          page.redirect != undefined && page.redirect.condition
                          ?
                            <Redirect to={page.redirect.to} /> 
                          :
                            <PageWrapper {...page.componentProps} />
                        }                        
                      </Route>
            })
          }
        </Switch>
      </RoomProvider>
    </Router>
  );
};

export default App;

/*
<Route exact path="/">
            QRPoc
          </Route>
          <Route path="/view/:productName">
            <Product />
          </Route>
          <Route path="/present">
            <Present />
          </Route>
          <Route path="/joinRoom">
            <JoinRoom />
          </Route>
          <Route path="/presenter">
            <PresenterProvider>
              <Presenter />
            </PresenterProvider>
          </Route>
          <Route path="*">404</Route>*/