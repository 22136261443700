import React, { useContext, useState } from 'react';
import BigScreenSlideshow from './BigScreenSlideshow';
import BigScreenHeader from './Header';
import BigScreenPDP from './BigScreenPDP';
import BigScreenFooter from './BigScreenFooter';
import { PresenterContext } from '../../context/PresenterContext';

const BigScreenWrapper = () => {

    const { presenterState, actions } = useContext(PresenterContext);

    return (
        <div className="flex flex-col h-full bg-white">
            
            <BigScreenHeader/>
            {
                presenterState && presenterState.selectedProduct
                ? 
                    <BigScreenPDP/>
                :
                <>
                    <BigScreenSlideshow/>
                    {/*
                    <div style={{height: "20%"}}>
                        <BigScreenFooter/>
                    </div>
                    */}
                </>
                    
            }
            
        </div>
    )
};

export default BigScreenWrapper;