import { useHistory, useLocation } from "react-router"

/**
 * A custom hook that contains a .push function which adds the previous path to the state everytime it's called
 * @returns Custom .push function as well as location and history hooks.
 */
const useHistoryHelper = () => {
    const history = useHistory();
    const location = useLocation();

    const push = (path) => {
        history.push({
            pathname: path,
            state: {
                prevPage: location.pathname
            }
        });
    }

    return {
        push: push,
        history: history,
        location: location
    };
}

export default useHistoryHelper;