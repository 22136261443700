import Slider from 'react-slick';

const sliderOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false    
}

const BigScreenSwatchPopup = ({variant, range}) => {
    //.Brand, 
    //.RangeId + .ColourId, .ShortName
    return(
        <div className="items-center flex flex-col h-full"> 
            <div className="text-center text-6xl font-bold mb-2">
                <div>{range.Range}</div><div>{variant.ShortName}</div>
            </div>
            <img className="h-4/6" src={`/api/products/image?image=products/swatches/${variant.Swatch}`} />
            <div className="mr-auto mt-auto">{variant.RangeId}{variant.ColourId}</div>
        </div>
    )
}

export default BigScreenSwatchPopup;