import { React, useEffect, useState, useContext } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";


import { PresenterContext } from "../context/PresenterContext";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import BigScreenWrapper from "../components/BigScreen/BigScreenWrapper";
import Slider from "react-slick";
import BigScreenFooter from "../components/BigScreen/BigScreenFooter";
import BigScreenIdleWarning from "../components/BigScreen/BigScreenIdleWarning";

const test = false;

const sliderOptions = {
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  infinite: true,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 8000
}

const useStyles = makeStyles({});

const Product = () => {
  const classes = useStyles();
  const { presenterState, actions } = useContext(PresenterContext);
  //const [connectionId, setConnectionId] = useState();
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch('/api/qr/getslideshow')
      .then((res) => res.json())
      .then((res) => {
        setImages(res);
      })
      .catch((err) => {
        console.log(err);
      })
    if(test){
      actions.loadPresentation(123);
    }
    

    actions.setConnection();
  }, []);

  return (
    <div style={{height: "100vh"}}>
      <div className="h-full">
      <BigScreenIdleWarning isOpen={presenterState.showWarning} />
      {presenterState.isPresenting ? (
        <>
          <BigScreenWrapper/>
        </>
      ) : (
        <div className="h-full flex flex-col">
          {
            presenterState.options.ShowQrCode &&
              <div style={{right: "10%", width: "8%"}} className="absolute bottom-0">
                {
                  presenterState.connection &&
                    <>
                    {
                      presenterState.offline 
                      ?
                        <div style={{height: "150px"}} className="text-7xl text-center">Offline</div>
                      :
                        <img src={`/api/Qr/PresenterQr?connectionId=${presenterState.connection.connectionId}`} alt="Present QR" />
                    }
                    
                    </>
                }              
              </div>
          }
          {/*
          <Slider {...sliderOptions}>
            {images.map((image) => {
              return <img className="h-full object-cover bg-black" src={image} />
            })}
          </Slider>
          <div style={{height: "20%"}} className="relative mt-auto bg-white">
            <BigScreenFooter/>
            <div style={{right: "10%", width: "18%"}} className="absolute bottom-0">
              {
                presenterState.connection &&
                  <>
                  {
                    presenterState.offline 
                    ?
                      <div style={{height: "150px"}} className="text-7xl text-center">Offline</div>
                    :
                      <img src={`/api/Qr/PresenterQr?connectionId=${presenterState.connection.connectionId}`} alt="Present QR" />
                  }
                  
                  </>
              }              
            </div>
            <div style={{right: "27%", bottom: "40%"}} className="absolute">
              <img src={"/images/arrow.svg"} />
            </div>
          </div>
          */}
        </div>
      )}
      </div>
    </div>
  );
};
//
export default Product;
