import { React, useEffect, useState, useContext } from "react";

const socialLinks = [
    {
        src: "/images/Instagram-logo.svg",
        href: "https://www.instagram.com/thefloorroom/"
    },
    {
        src: "/images/Facebook-logo.svg"
    },
    {
        src: "/images/Twitter-logo.svg"
    }
];

const AppFooter = () => {
    return (
        <div className="p-4 bg-crgray text-white font-bold text-xs pb-8">
            <img className="mb-4 w-1/2" src="/images/logo.png"/>
            <p className="mb-4">Carpetright Limited is registered in England at Purfleet Bypass, Purfleet, Essex, RM19 1TT. Telephone: 0330 333 3444</p>
            <p className="mb-4">Registered number: 2294875.  VAT ID: GB810 2986 41.</p>
            <p className="mb-4">Copyright Carpetright. All rights reserved.</p>
            <div className="flex footer-icons">
                {
                    socialLinks.map((social) => {
                        if(social.href){
                            return  <a href={social.href} key={social.href} target="_blank"> 
                                        <img src={social.src}/>
                                    </a>
                        }
                        
                    })
                }
            </div>
        </div>
    )
}

export default AppFooter;