import { React, useEffect, useState, useContext } from "react";
import AppFooter from "./AppFooter";
import AppHeader from "./AppHeader";

const PageWrapper = (props) => {

    return(
        <div>
            {
                !props.hideHeader &&
                <AppHeader/>
            }
            <div className={`${!props.hideHeader ? "pt-20" : ""}`}>
                {props.component}
            </div>
            {
                !props.hideFooter &&
                <AppFooter/>
            }
        </div>
    )
}

export default PageWrapper;