import {React, useContext, useEffect, useState} from 'react';
import useHistoryHelper from '../HistoryHelpers';
import { RoomContext } from "../../context/RoomContext";

const ViewWishlistButton = ({ mode }) => {

    const historyHelper = useHistoryHelper();
    const [buttonMode, setButtonMode] = useState("default");    
    const { products } = useContext(RoomContext);

    useEffect(() => {
        if(mode != undefined){
            setButtonMode(mode);
        }
    }, [mode]);

    return(
        <>
        {
            buttonMode == "wishlist" &&
                <div className="relative cursor-pointer" onClick={() => historyHelper.push("/wishlist") }>
                    <svg className="text-black" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="2 4 20 19" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                    <div className="absolute top-1 left-1/2 transform -translate-x-2/4 font-bold">{products.length}</div>
                </div>
        }
        {
            buttonMode == "default" &&
                <div className={`relative cursor-pointer ${products.length > 0 ? "text-black" : "text-cr_dark_gray"}`} onClick={() => historyHelper.push("/wishlist") }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="2 4 20 19" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                    <div style={{top: "45%"}} className="text-xl absolute left-1/2 transform -translate-x-2/4 -translate-y-2/4 font-bold">{products.length}</div>
                </div>
        }
            
        </>
    )
}

export default ViewWishlistButton;