import React, { useRef } from "react";
import { useEffect } from "react";

const BigScreenPopupWrapper = ({isOpen, onClickClose, title, children, hideUnderlay}) => {

    //showCloseButton

    const container = useRef();

    useEffect(() => {
        if(isOpen){ //Opening
            container.current.style.display = "unset";
            setTimeout(() => {
                container.current.style.opacity = "100%";
            }, 100);            
        }
        else{
            container.current.style.opacity = "0%";
            setTimeout(() => {
                container.current.style.display = "none";
            }, 500);
        }
    }, [isOpen])

    useEffect(() =>{ 
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        //console.log(event.target, container.current.contains(event.target), onClickClose);
        if (container && (!container.current.contains(event.target) || container.current == event.target) && onClickClose) {
            //onClickClose();
        }
    }

    return(
        <div style={{display: "none", width: "29%", height: "57%"}} className="p-4 bg-white absolute border-black border-4 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 transition-opacity duration-500" ref={container}>
            {
                onClickClose &&
                <div style={{top: "-35px", right: "-30px"}} className="absolute bg-black text-white p-4 border-2 border-white font-bold cursor-pointer text-4xl leading-8" onClick={() => { onClickClose(); }}>
                    X
                </div>
            }                
            {
                title &&
                <div className="p-8 text-3xl bg-black text-white text-center font-medium">
                    {title}
                </div>
            }               

            {children}

        </div>
    )
}

export default BigScreenPopupWrapper;