import { React, useEffect, useState, useContext } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

import { RoomContext } from "../context/RoomContext";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router";

const useStyles = makeStyles({});

const Present = () => {
  const classes = useStyles();
  const [error, setError] = useState();
  const { connectionId } = useParams();
  const history = useHistory();

  const { roomId } = useContext(RoomContext);

  useEffect(() => {
    if (roomId != null) {
      Axios.post(`/api/rooms/Present`,{
        roomId: roomId,
        connectionId: connectionId
      })
        .then((res) => {
          //console.log(res);
        })
        .catch(({ response }) => {
          let res = response.data;
          console.log(res);
          if(res.Error){
            if(res.Error == "NO_PRODUCTS"){
              history.push("/wishlist");
            }
          }
          setError(res);
        });
    }
  }, [roomId]);

  return (
    <div style={{height: "57vh"}}>
    <Container>
      <Typography variant="h2" gutterBottom>
        Presentation Requested
      </Typography>
      {error && <Alert severity="error">{error.Message}</Alert>}
    </Container>
    </div>
  );
};

export default Present;
