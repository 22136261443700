import React, { useContext, useEffect, useRef, useState } from 'react';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { PresenterContext } from '../../context/PresenterContext';

const BigScreenEmailPopup = ({isOpen, onClickClose}) => {

    const container = useRef();
    const form = useRef();
    const [responseMessage, setResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [currentLayout, setCurrentLayout] = useState("default");
    const {presenterState, actions} = useContext(PresenterContext);

    useEffect(() => {
        if(isOpen){ //Opening
            container.current.style.display = "unset";
            setTimeout(() => {
                container.current.style.opacity = "100%";
            }, 100);            
        }
        else{
            container.current.style.opacity = "0%";
            setTimeout(() => {
                container.current.style.display = "none";
            }, 500);
        }
    }, [isOpen])

    const handleSubmit = (e) => {
        let errorMessage = "Sorry, an error occurred. Please try again.";
        setLoading(true);
        setResponse("");
        e.preventDefault();
        fetch('/api/rooms/SendWishlistEmail', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
                EmailTo: email,
                RoomId: presenterState.roomId
            })
        })
        .then(res => {
            //console.log(res);
            
            if(res.ok){
                setResponse("Email successfully sent.");    
            }
            else{
                setResponse(errorMessage);
            }
        })
        .catch(err => {
            console.log(err);
            setResponse(errorMessage);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const keyboardOnChange = (e) => {
        setEmail(e);
    }

    const onKeyPress = (e) => {
        if(e === "{enter}"){
            form.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles:true }));
        }
        if(e === "{shift}"){
            setCurrentLayout(currentLayout == "default" ? "upper" : "default");
        }
    }

    return(
        <div style={{display: "none"}} className="absolute w-full h-full left-0 top-0 z-20 bg-black bg-opacity-50 transition-opacity duration-500" ref={container}>
            <div style={{top: "30%"}} className="left-1/2 relative w-3/12 -translate-x-1/2 -translate-y-1/2 transform">
                <div style={{top: "-35px", right: "-30px"}} className="absolute bg-black text-white p-4 border-2 border-white font-bold cursor-pointer text-4xl leading-8" onClick={() => { onClickClose(); }}>
                    X
                </div>
                <div className="p-8 text-3xl bg-black text-white text-center font-medium">
                    Receive your favourites by email
                </div>
                <div className="p-4 bg-white">
                    <div className="text-center text-sm px-24 font-bold">
                        Please enter your email address and we'll send your favourites list to you.
                    </div>
                    <form className="mt-2 mb-4 flex flex-col" onSubmit={handleSubmit} ref={form}>
                        <input className="border border-black p-4" placeholder="Email address..." value={email} onChange={(e) => { setEmail(e.target.value); }} />
                        <button className="flex justify-center cursor-pointer mt-4 mx-auto w-5/12 bg-black text-white px-4 py-2 font-medium rounded-md text-2xl" type="submit"><div className="mr-2">Send</div>{loading && <img className="h-7 animate-spin" src="/images/spinner.png"/>}</button>
                    </form>
                    <div className="text-center">{responseMessage}</div>
                    <div className="text-center text-sm font-bold">
                        Your information will be passed on to the store team, so that they can help you.
                    </div>
                </div>
            </div>
            <div style={{width: "60%"}} className="absolute bottom-6 transform -translate-x-1/2 left-1/2 text-2xl">
                <Keyboard 
                    onChange={keyboardOnChange}
                    onKeyPress={onKeyPress}
                    layoutName={currentLayout}
                    layout={{
                        default: [
                            "1 2 3 4 5 6 7 8 9 0 + ~",
                            "q w e r t y u i o p {bksp}",
                            "a s d f g h j k l _ -",
                            "{shift} z x c v b n m @ .",
                            "| ? { .com .co.uk {enter}"
                        ],
                        upper: [
                            "` ! $ % ^ & * ' = # /",
                            "Q W E R T Y U I O P {bksp}",
                            "A S D F G H J K L _ -",
                            "{shift} Z X C V B N M @ .",
                            "| ? { .com .co.uk {enter}"
                        ]
                    }}
                    display={{
                        '{shift}': 'shift',
                        '{bksp}': 'backspace',
                        '{space}': ' ',
                        '{numbers}': '123',
                        '{enter}': 'Submit'
                    }}
                    buttonTheme={[
                        {
                            class: 'hg-shade',
                            buttons: '{shift} {numbers} {bksp}'
                        },
                        {
                            class: 'hg-dark',
                            buttons: '{enter}'
                        },
                        {
                            class: 'hg-small',
                            buttons: '| ? {'
                        },
                        {
                            class: 'mw-unset',
                            buttons: '.com'
                        }
                    ]}
                />
            </div>
        </div>
    )
}

/*
        "q w e r t y u i o p {bksp}",
        "a s d f g h j k l",
        "{shift} z x c v b n m @ .",

*/


export default BigScreenEmailPopup;