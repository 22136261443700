import { React, useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

import { RoomContext } from "../context/RoomContext";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import useHistoryHelper from "../components/HistoryHelpers";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReadMore from "../components/MobileApp/ReadMore";

const useStyles = makeStyles({});

const productData = {
  flooringType: "CARPET",
  images: [
    "/images/saxony.jpg",
    "/images/saxony.jpg"
  ],
  brand: "/images/house-beautiful.png", //Will this be a logo or just text?
  range: "Portobello",
  miniDescription: "Woven & hand-finished to the very highest quality",
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  colours: [
    "bg-red-500",
    "bg-blue-500",
    "bg-yellow-500",
    "bg-red-500",
    "bg-blue-500",
    "bg-yellow-500",
    "bg-red-500",
  ],
  icons: [
    "2v Groove icon.png",
    "icon-country-living-uk.png",
    "icon-deep-super-soft.png",
    "icon-kosset-moth.png",
    "icon-patterns-bleach.png",
  ],
  rooms: [
    "WWB_Bathroom_SPOT.png",
    "WWB_Bedroom_SPOT.png",
    "WWB_Dining_Room_SPOT.png",
    "WWB_Hall_SPOT.png",
  ]
}

const productSliderSettings = {
  dots: true,
  arrows: false
}

const colourSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  variableWidth: true,
  arrows: false
}

const Product = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [product, setProduct] = useState();
  const [selectedVariant, setSelectedVariant] = useState();
  const [error, setError] = useState();
  const [isInWishlist, setIsInWishlist] = useState(false);
  const history = useHistory();

  const { roomId, products, actions } = useContext(RoomContext);

  const handleWishlistToggleClick = () => {
    //if in wishlist, remove else add
    //setIsInWishlist(isInWishlist => !isInWishlist);
    if(isInWishlist){
      actions.removeProductFromRoom(roomId, product.Id, selectedVariant ? selectedVariant.ColourId : null);
    }else
    {
      actions.addProductToRoom(roomId, product.Id, selectedVariant ? selectedVariant.ColourId : null);
    }
  }

  useEffect(() => {
      if(product){
        setIsInWishlist(products.some((prod) => prod.Id == product.Id));
      }
  }, [products, product]);

  useEffect(() => {
    if(id.length > 4){
      let rangeId = id.substring(0, 4);
      let colourId = id.substring(4, 8);
      Axios.get(`/api/products/GetVariantByColourId?rangeId=${rangeId}&colourId=${colourId}`)
        .then((res) => {
          setProduct(res.data);
          setSelectedVariant(res.data.SelectedVariant);
        })
        .catch(({response}) => {
          console.log(response.data.Message);

          setError(response.data.Message);
        })
    }
    else{
      let newId = id;
      if(id == "0166"){
        newId = "6545"; //Data from Carpetright is incorrect, .csv provided contains duplicate that we are handling here.
      }
      Axios.get(`/api/products/GetById?rangeId=${newId}`)
        .then((res) => {
          console.log(res.data);
          setProduct(res.data);
        })
        .catch(({ response }) => {
          console.log(response);
          setError(response.data.Message);
        });
      }
  }, [id]);

  return (
    <div className="Mobile-Product">
      {
        product 
        ?
          <div>
            <div className="text-5xl font-bold px-4 pb-2 pt-4 shadow-inner cr-product__heading-text uppercase">
              {product.CarpetType}
            </div>
            <div style={{maxHeight: "400px"}} className="relative mx-auto">
              <div className={`heart-icon__animation ${isInWishlist ? "heart-icon__animation-end" : "heart-icon__animation-start"} text-white z-10 absolute w-16 text-black top-2 right-6`} onClick={handleWishlistToggleClick}><svg xmlns="http://www.w3.org/2000/svg" fill={`none`} viewBox="2 4 20 19" stroke={`currentColor`}><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg><div style={{top: "40%"}} className={`font-bold text-3xl absolute left-1/2 transform -translate-x-2/4 -translate-y-2/4`}>+</div></div>
              {
                selectedVariant && false
                ? 
                  <img className="mh-inherit w-full h-full object-cover" src={`/api/products/image?image=products/swatches/${selectedVariant.Swatch}&size=500x500`}/>
                :
                  <Slider {...productSliderSettings}>
                    {product.Images.map((img) => {
                      return  <div key={`sliderImages_${img}`}>
                                <img style={{minHeight: "400px"}} className="w-full h-full object-cover" src={`/api/products/image?image=products/range/${img}&size=500x500`}/>                         
                              </div>
                    })}
                  </Slider>
              }
              
              {/*<img src="/images/saxony.jpg" style={{maxHeight: "75vw"}} className="border-8 border-white w-full object-cover"/>*/}
            </div>
            <div className="p-4">
                <div>
                  {
                    <img className="object-contain pl-1 mb-1" src={`/images/brand/${product.Brand ? product.Brand.toLowerCase() : "core"}.png`}/> 
                  }                  
                  <div className="font-bold mt-2 text-4xl">{product.Range}</div>
                </div>
                {/*<div className="grid grid-cols-5 gap-4 my-4">
                  {product.Variants.map((variant) => {
                    return <div key={`colourBlock_${variant.Sku}`} className={`pt-full relative`}><img src={`/api/products/image?image=products/swatches/${variant.Swatch}&size=50x50`}  className="absolute top-0 left-0 h-full" ></img></div>
                  })}

                </div>*/}
                {
                  !selectedVariant &&
                  <div>
                    <Slider {...colourSliderSettings}>
                      {product.Variants.map((variant) => {
                        return <div style={{width: "60px"}} key={`colourBlock_${variant.ColourId}${variant.ShortName}`}><img src={`/api/products/image?image=products/swatches/${variant.Swatch}&size=50x50`}  className="pr-2 h-full" ></img></div>
                      })}
                    </Slider>
                  </div>
                }
                <ReadMore disabled={true} maxHeight={300} content={selectedVariant ? selectedVariant.ProductText : product.RangeText}></ReadMore>
                <div className="my-6 grid grid-flow-col gap-4">
                  {
                    product.Icons.map((icon) => {
                      return <img src={`/icons/${icon}`} key={`icon__${icon}`}/>
                    })
                  }
                </div>
                {
                  product.RoomIcons.length > 0 &&
                  <div>
                    <div className="font-bold text-2xl text-center">Where it works best</div>
                    <div className="grid grid-flow-col gap-4">
                      {
                        product.RoomIcons.map((room) => {
                          return <img src={`/icons/${room}`} key={`roomIcon__${room}`}/>
                        })
                      }
                    </div>
                  </div>
                }
                <div className="grid gap-4 my-6">
                  <button className="font-semibold h-14 text-white bg-black flex items-center justify-center" onClick={handleWishlistToggleClick}>
                      <div className={`mr-2 heart-icon__dark heart-icon__animation ${isInWishlist ? "heart-icon__animation-end text-black" : "heart-icon__animation-start text-white"} w-12 relative`} ><svg xmlns="http://www.w3.org/2000/svg" viewBox="2 4 20 19"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg><div style={{top: "40%"}} className={`font-bold text-2xl absolute left-1/2 transform -translate-x-2/4 -translate-y-2/4`}>+</div></div>
                      {isInWishlist ? "Remove from my favourites" : "Add to my favourites"}
                  </button>
                  <button onClick={() => { history.push("/wishlist"); }} className="disabled:border-gray-400 disabled:text-gray-400 font-semibold h-14 flex items-center justify-center border-2 border-black" disabled={products.length == 0}>
                     View my favourites
                  </button>
                </div>
            </div>
          </div>
        :
          <div>
          </div>
      }
  
      
      <Container >
        {product ? (
          <div>
            
          </div>
        ) : (
          !error ? (
            <div style={{minHeight: "57vh", paddingTop: "2rem"}}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h2" gutterBottom>
                  Finding your selection
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            </div>
          )
          :
            <div  style={{minHeight: "57vh", paddingTop: "2rem"}}>
              <Alert severity="error">{error}</Alert>
            </div>
        ) 
            
      }
      </Container>
    </div>
  );
};

export default Product;
