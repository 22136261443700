import React, { useContext, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { PresenterContext } from '../../context/PresenterContext';
import useDragDetection from '../useDragDetection';
import './BigScreenPDP.scss';
import BigScreenPopupWrapper from './BigScreenPopupWrapper';
import BigScreenSwatchPopup from './BigScreenSwatchPopup';

const sliderOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    infinite: false,
    arrows: false    
}

const sliderOptionsImages = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false,
    nextArrow: <button type="button" data-role="none" class="slick-arrow slick-next" style="display: block;"><img src="/images/ArrowRight.svg"/> </button>,
    prevArrow: <button type="button" data-role="none" class="slick-arrow slick-prev" style="display: block;"><img src="/images/ArrowLeft.svg"/> </button>
}


const BigScreenPDP = () => {

    const { presenterState, actions } = useContext(PresenterContext);

    const [product, setProduct] = useState();
    const [selectedVariant, setVariant] = useState();
    const [isVariantPopupOpen, setVariantPopupOpen] = useState(false);
    const textBox = useRef();

    const {handleMouseDown, dragging, } = useDragDetection();
    
    function handleChildClick(e, variant) {
        if (dragging) {
            e.preventDefault();
        }
        else{
            console.log(variant);
            setVariantPopupOpen(true);
            setVariant(variant);
        }
    }

    useEffect(() => {
        if(presenterState.selectedProduct){
            setProduct(presenterState.selectedProduct);
        }        
    }, [presenterState.selectedProduct]);

    let x = 0;
    let y = 0;

    let pos = { top: 0, left: 0, x: 0, y: 0 };

    // Handle the mousedown event
    // that's triggered when user drags the element
    const mouseDownHandler = function(e) {
        pos = {
            // The current scroll 
            left: textBox.current.scrollLeft,
            top: textBox.current.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        };

        // Change the cursor and prevent user from selecting the text
        textBox.current.style.cursor = 'grabbing';
        textBox.current.style.userSelect = 'none';
        
        // Attach the listeners to `document`
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function(e) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        textBox.current.scrollTop = pos.top - dy;
        textBox.current.scrollLeft = pos.left - dx;
    };

    const mouseUpHandler = function() {
        // Remove the handlers of `mousemove` and `mouseup`
        textBox.current.style.cursor = 'grab';
        textBox.current.style.removeProperty('user-select');
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    return( 
        <>
            {
                product &&
                <>
                <div className="overflow-y-hidden flex BigScreenPDP flex-grow flex-col">
                    <div className="flex" style={{height: "80%"}}>
                        <div style={{width: "44.5%"}} className="flex h-full">
                            <div className="relative w-full h-full">
                                <Slider {...sliderOptionsImages}>
                                    {product.Images.map((img) => {
                                    return  <div key={`sliderImages_${img}`}>
                                                <img className="w-full h-full object-contain" src={`/api/products/image?image=products/range/${img}&size=1000x1000`} />
                                            </div>
                                    })}
                                </Slider>
                                <div onClick={() => { actions.removeProduct(product.Id, product.SelectedVariant != null ? product.SelectedVariant.ColourId : null); }} className="absolute top-6 right-6 flex items-center bg-gray-200 hover:bg-opacity-100 transition-all duration-100 bg-opacity-60 cursor-pointer px-4 py-1">
                                    <div className="font-bold">
                                        Remove from wishlist
                                    </div>
                                    <div className="relative w-12 mx-2">
                                        <svg className="text-black" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="2 4 20 19" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                                        <div style={{top: "45%"}} className="text-white font-bold text-xl transform absolute left-1/2 -translate-x-2/4 -translate-y-2/4">-</div>
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                        <div style={{width: "55.5%"}} className="p-4 flex flex-col">
                            <div className="cr-product__heading-text font-bold text-7xl uppercase">
                                {product.CarpetType}
                            </div>
                            <div className="h-3/4 flex-grow flex flex-col">
                                <div className="mb-4 flex flex-col min-h-0">
                                    <div className="my-4">
                                        {
                                            product.Brand &&
                                            <img style={{width: "50%"}} className="object-contain pl-1 mb-3" src={`/images/brand/${product.Brand ? product.Brand.toLowerCase() : "core"}.png`}/> 
                                        }
                                        <div className="font-bold text-5xl">{product.Range}{product.SelectedVariant && " " + product.SelectedVariant.ShortName}</div>
                                    </div>
                                    <div className="font-bold flex flex-col min-h-0 mr-12">
                                        <div className="text-4xl mb-2">
                                            Product information
                                        </div>
                                        <div className="text-2xl overflow-auto" ref={textBox} onMouseDown={mouseDownHandler} >
                                            {
                                                product.SelectedVariant != null ? product.SelectedVariant.ProductText : product.RangeText
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-auto w-6/12 pt-4">
                                    <div className="mb-4 grid grid-cols-5 gap-4">
                                        {
                                        product.Icons.map((icon) => {
                                            return <img src={`/icons/${icon}`} key={`icon__${icon}`} />
                                        })
                                        }
                                    </div>
                                    <div>
                                        <div className="grid grid-cols-5 gap-4">
                                        {
                                            product.RoomIcons.map((room) => {
                                                return <img src={`/icons/${room}`} key={`roomIcon__${room}`} />
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex" style={{height: "20%"}}>
                        <div className="pl-4" style={{width: "90%"}}>
                            {
                                product.SelectedVariant 
                                ?
                                <div style={{width: "18vh"}} onClick={(e) => { handleChildClick(e, product.SelectedVariant); }} className="colour-slide-block__container p-3"> <ColourSliderBlock shortName={product.SelectedVariant.ShortName} colour={product.SelectedVariant.Swatch} isSelected={false} /> </div>
                                :
                                <Slider {...sliderOptions}>
                                    {product.Variants.map((variant) => {
                                        return  <div style={{width: "18vh"}} key={`colourBlock_${variant.ColourId}${variant.ShortName}`} onMouseDownCapture={handleMouseDown} onClickCapture={(e) => { handleChildClick(e, variant); } } className="colour-slide-block__container p-3"> <ColourSliderBlock shortName={variant.ShortName} colour={variant.Swatch} isSelected={false} /> </div>
                                    })}
                                </Slider>
                            }
                        </div>
                        <div style={{width: "10%"}}>
                            <img className="ml-auto p-6 w-full" src="/images/thefloorroom.png"/>
                        </div>
                    </div>
                    
                </div>
                <BigScreenPopupWrapper
                    isOpen={isVariantPopupOpen}
                    onClickClose={() => { setVariantPopupOpen(false); } }
                >
                        {
                            selectedVariant != null &&
                            <BigScreenSwatchPopup variant={selectedVariant} range={product}/>
                        }                        
                    </BigScreenPopupWrapper>
                </>
            }
        </>
    )
}

const ColourSliderBlock = (props) => {

    return(
        <div className={`${props.isSelected && "border border-black"} p-3`}>
            <div className="relative">
                <img className="absolute w-full h-full object-cover" src={`/api/products/image?image=products/swatches/${props.colour}&size=100x100`} />
                <div style={{height: "auto"}} className="bg-black bg-opacity-40 py-2 text-lg text-white absolute bottom-0 h-auto w-full text-center">{props.shortName}</div>
            </div>
        </div>
    )
}

export default BigScreenPDP;