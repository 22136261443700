import { React, useEffect, useState, useContext } from "react";
import ViewWishlistButton from "./ViewWishlistButton";

const AppHeaderDefault = ({onHelpClick}) => {
    //TODO: Change this so it matches the default header of the wireframes.
    return (
            <div className="p-4 flex items-center fixed z-20 bg-white w-full shadow-lg">
                <div>
                    <div className="font-bold leading-3 mb-1">Welcome to</div>
                    <div className="font-bold text-3xl leading-6">the floor room</div>
                </div>
                <div className="flex-grow">
                    <svg onClick={onHelpClick} xmlns="http://www.w3.org/2000/svg" className="w-9 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                </div>
                <div className="w-12">
                    <ViewWishlistButton/>
                </div>
            </div>
    )
};

export default AppHeaderDefault;