import React, { useContext, useEffect, useRef, useState } from 'react';
import { PresenterContext } from '../../context/PresenterContext';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useDragDetection from '../useDragDetection';

const sliderOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    infinite: false,
    nextArrow: <button type="button" data-role="none" class="slick-arrow slick-next" style="display: block;"><img src="/images/ArrowRight.svg"/> </button>,
    prevArrow: <button type="button" data-role="none" class="slick-arrow slick-prev" style="display: block;"><img src="/images/ArrowLeft.svg"/> </button>
}

const BigScreenSlideshow = () => {

    const [width, setWidth] = useState("400px");
    const container = useRef();
    
    const { presenterState, actions } = useContext(PresenterContext);

    const {handleMouseDown, dragging, } = useDragDetection();

    function handleChildClick(e, id, colourId) {
        if (dragging) {
            e.preventDefault();
        }
        else{
            //console.log(id);
            if(id){
                actions.selectProduct(id, colourId);
            }
        }
    }

    const onRemoveFromWishlist = (id, colourId) => {
        actions.removeProduct(id, colourId);
    }

    return(
        <div ref={container} className="min-h-0 BigScreen-Product-Slideshow flex-1">
            {
                presenterState.products.length > 0 
                ?                
                    <Slider {...sliderOptions}>
                        {presenterState.products.map((prod) => {
                            return  <div key={Math.random() * 1000} style={{width: "70vh"}} className="product-block__container py-4 relative h-full"> <BigScreenSlideshowProduct product={prod} handleMouseDown={handleMouseDown} handleChildClick={handleChildClick} onRemoveFromWishlist={onRemoveFromWishlist} /> </div>
                        })}
                    </Slider>
                :
                    <div className="h-full w-full flex items-center justify-center">
                        <div className="text-4xl">
                        Your wishlist contains no products
                        </div>
                    </div>
            }
        </div>
    )
};

const BigScreenSlideshowProduct = (data) => {
    let product = data.product;
    //console.log(data);
    return (
        <>
        <div className="mx-4 cursor-pointer h-full" onMouseDownCapture={data.handleMouseDown} onClickCapture={(e) => { data.handleChildClick(e, product.Id, product.SelectedVariant ? product.SelectedVariant.ColourId : undefined) } }> 
            <div className="relative h-3/4 w-full">
                <img className="h-full w-full object-cover" src={`/api/products/image?image=products/range/${product.Images[0]}&size=700x700`} />
            </div>
            <div className="flex flex-col h-1/4">
                {
                    <img style={{width: "50%"}} className="object-contain pl-1 mb-1" src={`/images/brand/${product.Brand ? product.Brand.toLowerCase() : "core"}.png`}/> 
                }
                <div className="font-bold text-5xl mt-2 mb-auto">{product.Range}{product.SelectedVariant && " " + product.SelectedVariant.ShortName}</div>
                <div className="grid grid-cols-6 gap-3 my-4">
                    {
                        product.SelectedVariant 
                        ? 
                        <div className={`pt-full relative`}><img src={`/api/products/image?image=products/swatches/${product.SelectedVariant.Swatch}&size=50x50`} className="absolute top-0 left-0 h-full" ></img></div>
                        :
                        product.Variants.slice(0, 6).map((variant) => {
                            return <div key={`colourBlock_${variant.ColourId}${variant.ShortName}`} className={`pt-full relative`}><img src={`/api/products/image?image=products/swatches/${variant.Swatch}&size=50x50`} className="absolute top-0 left-0 h-full" ></img></div>
                        })
                    }
                </div>
            </div>
            
        </div>
        <div onClick={() => { data.onRemoveFromWishlist(product.Id, product.SelectedVariant ? product.SelectedVariant.ColourId : undefined) } } className="absolute top-6 right-6 flex items-center bg-gray-200 hover:bg-opacity-100 transition-all duration-100 bg-opacity-60 cursor-pointer px-4 py-1">
            <div className="font-bold">
                Remove from wishlist
            </div>
            <div className="relative w-12 mx-2">
                <svg className="text-black" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="2 4 20 19" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                <div style={{top: "45%"}} className="text-white font-bold text-xl transform absolute left-1/2 -translate-x-2/4 -translate-y-2/4">-</div>
            </div>
        </div>
        </>
    )
}

export default BigScreenSlideshow;