import React, { useContext, useState } from 'react';
import { PresenterContext } from '../../context/PresenterContext';
import BigScreenEmailPopup from './BigScreenEmailPopup';
import BigScreenIdleWarning from './BigScreenIdleWarning';

const BigScreenHeader = () => {

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const {presenterState, actions} = useContext(PresenterContext);

    return (
        <>
            <div style={{minHeight: "10%"}} className="bg-black px-4 flex items-center">
                {presenterState.selectedProduct &&
                    <div className="mr-8 text-white border-2 border-white cursor-pointer" onClick={() => { actions.clearProduct() } }>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-14" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" /></svg>
                    </div>
                }
                <div className="cr-product__heading-text-dark font-bold text-6xl">
                    YOUR FAVOURITES
                </div>
                <div className="relative cursor-pointer w-16 mx-8"  onClick={() => { actions.clearProduct() } }>
                    <svg className="text-white" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="2 4 20 19" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                    <div style={{top: "45%"}} className="text-black font-bold text-3xl transform absolute left-1/2 -translate-x-2/4 -translate-y-2/4">{presenterState.products.length}</div>
                </div>
                <div className="bg-white px-6 py-2 flex cursor-pointer" onClick={() => { setIsPopupOpen(true); }}>
                    <svg className="w-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                    <div className="whitespace-nowrap">Receive by email</div>
                </div>
                {
                    !presenterState.selectedProduct &&
                    <div className="ml-auto text-white p-4 border-2 border-white font-bold cursor-pointer text-4xl leading-8" onClick={() => { actions.stopConnection(); }}>
                        X
                    </div>
                }
            </div>
            <BigScreenEmailPopup isOpen={isPopupOpen} onClickClose={() => { setIsPopupOpen(false) }} />
            
        </>
    )
}

export default BigScreenHeader;