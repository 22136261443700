import React, { useState, createContext, useMemo, useEffect } from "react";
import Axios from "axios";
import Cookies from "js-cookie";

const RoomContext = createContext();

const RoomProvider = (props) => {
  const [roomId, setRoomId] = useState(null);
  const [products, setProducts] = useState([]); //Wishlist
  const [storeLocation, setStoreLocation] = useState("Tottenham Court Road, London");

  const updateRoomCookie = (roomId) => {
    //Cookies.set("roomId", roomId);
    localStorage.setItem("roomId", roomId);
    let tomorrow = Date.now() + 86400000; //24 Hours
    localStorage.setItem("expiry", tomorrow);
  };

  const createAndJoinRoom = () => {
    Axios.post("/api/rooms/createroom").then((res) => {
      const roomId = res.data.Id;
      updateRoomCookie(roomId);
      setRoomId(roomId);
    });
  };

  const contextValue = useMemo(() => {
    return {
      roomId,
      products,
      storeLocation,
      actions: {
        joinRoom: (roomId) => {
          Axios.get(`/api/rooms/getById?roomId=${roomId}`)
            .then((res) => {
              updateRoomCookie(roomId);
              setRoomId(roomId);

              console.log(`Joined room: ${roomId}.`);
            })
            .catch(({ response }) => {
              console.error(`Failed to join room: ${roomId}!`, response);
            });
        },
        addProductToRoom: (roomId, productId, colourId) => {
          if (roomId != null && productId != null) {
            // Will return current room with all products in.
            Axios.post(`/api/rooms/addProduct`, {
              roomId: roomId,
              rangeId: productId,
              colourId: colourId
            })
            .then((res) => {
              setProducts(res.data.Products);
            })
            .catch(({ response }) => {
              //setError(response.data);
              console.log(response);
            });
          }
          else{
            console.log("Error: roomId", roomId, " or productId", productId, "is null");
          }
        },
        removeProductFromRoom: (roomId, productId, colourId) => {
          if(roomId != null && productId != null){
            Axios.post('/api/rooms/removeProduct', {
              roomId: roomId,
              rangeId: productId,
              colourId: colourId
            })
            .then((res) => {
              setProducts(res.data.Products);
            })
            .catch(({res}) => {
              console.log(res);
            })
          }
        }
      }
    };
  }, [roomId, products]);

  useEffect(() => {
    //let roomId = Cookies.get("roomId");
    let expiry = Number.parseInt(localStorage.getItem("expiry"));
    if(Date.now() > expiry){ //If expired, get a new room ID
      localStorage.removeItem("roomId");
    }

    let roomId = localStorage.getItem("roomId");

    if (roomId) {
      Axios.get(`/api/rooms/getById?roomId=${roomId}`)
        .then((res) => {
          setRoomId(roomId);
          setProducts(res.data.Products);
        })
        .catch((err) => {
          console.error(
            "Failed to recall room! Creating and joining new room!",
            err
          );
          createAndJoinRoom();
        });
    } else {
      createAndJoinRoom();
    }
  }, []);

  return (
    <RoomContext.Provider value={contextValue}>
      {props.children}
    </RoomContext.Provider>
  );
};

export { RoomProvider, RoomContext };
