import React, { useEffect, useRef, useState } from 'react';

const BigScreenIdleWarning = ({isOpen, onClickClose}) => {

    const container = useRef();

    useEffect(() => {
        if(isOpen){ //Opening
            container.current.style.display = "unset";
            setTimeout(() => {
                container.current.style.opacity = "100%";
            }, 100);            
        }
        else{
            container.current.style.opacity = "0%";
            setTimeout(() => {
                container.current.style.display = "none";
            }, 500);
        }
    }, [isOpen]);

    return(
        <div style={{display: "none"}} className="absolute w-full h-full left-0 top-0 z-50 bg-black bg-opacity-50 transition-opacity duration-500" ref={container}>
            <div style={{top: "50%"}} className="left-1/2 relative w-6/12 -translate-x-1/2 -translate-y-1/2 transform">
                <div className="p-8 text-3xl bg-black text-white text-center font-medium">
                    Presentation ending soon
                </div>
                <div className="p-4 bg-white">
                    <div className="text-center px-24 font-bold">
                        Due to inactivity, this presentation will end in 2 minutes. Please touch the screen to continue the presentation
                    </div>
                </div>
            </div>
        </div>
    )
}


export default BigScreenIdleWarning;