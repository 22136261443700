import React, { useEffect, useRef } from 'react';
import EmailPopup from './EmailPopup';
import HelpPopup from './HelpPopup';

export const IPopup = {
    Help: "help",
    Email: "email"
}

export const PopupWrapper = ({isOpen, onClickClose, popup}) => {

    const container = useRef();
    const content = useRef();

    useEffect(() => {
        //console.log("hello", isOpen);
        if(container){
            if(isOpen){ //Opening
                container.current.style.display = "unset";
                setTimeout(() => {
                    container.current.style.opacity = "100%";
                }, 100);            
            }
            else{
                container.current.style.opacity = "0%";
                setTimeout(() => {
                    container.current.style.display = "none";
                }, 500);
            }
        }
    }, [isOpen, container])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    const handleClickOutside = (e) => {
        if (content && !content.current.contains(e.target)) {
            onClickClose();
        }
    }

    const renderPopupContent = () => {
        switch(popup){
            case IPopup.Help:
                return <HelpPopup/>
            case IPopup.Email:
                return <EmailPopup/>
            default:
                return <HelpPopup/>
        }
    }

    return(
        <>
            <div style={{display: "none"}} className="mt-20 top-0 w-full fixed z-20 text-white px-2 h-screen v-gradient__black transition-opacity duration-500" ref={container}>
                <div className="bg-cr_shark mt-4 relative rounded-2xl" ref={content}>
                    <div className="absolute right-0 bg-cr_shark -top-3 border-2 rounded-full w-8 h-8 flex justify-center items-center" onClick={onClickClose} >X</div>
                    <div className="py-8 px-2">
                        {renderPopupContent()}
                    </div>
                </div>
            </div>
        </>
    )
}