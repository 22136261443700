import React, { useEffect, useRef } from 'react';

const HelpPopup = () => {

    return (
        <ol className="px-12 list-decimal help-popup__list font-medium list-inside"> 
            <li className="content__QR">Scan the QR codes of the products that inspire you.</li>
            <li className="content__Heart">Click the heart to add them to your favourites</li>
            <li className="content__Monitor">Scan the QR code located on the big screen in store and our experts will talk you through the finer details</li>
        </ol>
    )
}

export default HelpPopup;